import React from "react";

function AdviceCard({ data }) {
  console.log(data);
  return (
      <div className="w-full max-w-sm p-4 text-white rounded-xl">
        <div className="flex items-center justify-between">
          <h2 className="font-700 text-14">{data.type}</h2>
          <button className="cursor-pointer text-10 font-bold text-[#CE992E]">
            Watch Video
          </button>
        </div>
        <p className="mt-2 font-normal text-gray-400 text-10">
          {data.description}...
          <span className="text-white font-700">Read more</span>
        </p>
        <button
          style={{
            background:
              "linear-gradient(89.64deg, #E6B903 -1.29%, #F5D882 98.65%)",
          }}
          className="w-full px-3 py-2 mt-4 font-semibold text-black rounded-lg cursor-pointer bg-gradient-to-r from-yellow-400 to-yellow-600 text-12"
        >
          Improve Score Now
        </button>
      </div>
  );
}

export default AdviceCard;
