import React, { useEffect } from "react";
import Success from "./../Images/Success.json"; // Ensure this path is correct
// import LottiePlayer from "./LottiePlayer"; // Ensure this path is correct
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import FooterLinksV2 from "../containers/FooterLinks";
import { useDispatch } from "react-redux";
import { getUserPaymentReq } from "../redux/actions/common";

function SuccessPayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orderId =
    typeof localStorage !== "undefined"
      ? localStorage.getItem("orderId")
      : false;

  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate("/score");
  //   }, 7000);
  // }, []);
  useEffect(() => {
    if (orderId) {
      dispatch(
        getUserPaymentReq({
          id: orderId,
          successAction: () => {
            setTimeout(() => {
              navigate("/app-links");
            }, 4000);
          },
          errorAction: () => {
            setTimeout(() => {
              navigate("/app-links");
            }, 4000);
          },
        })
      );
    } else {
      setTimeout(() => {
        navigate("/app-links");
      }, 4000);
    }
  }, []);
  return (
    <>
      <div className="flex flex-col gap-3 justify-center items-center p-4 h-screen">
        <div className="w-1/2 text-center text-customGold text-30 font-700">
          Payment Successful
        </div>
        <Lottie
          loop
          animationData={Success}
          play
          style={{ width: 150, height: 150 }}
        />
        <div className="w-1/2 text-center text-white text-17">
          Congratulation on your score improvement Journey{" "}
        </div>
      </div>
      <div className="md:mx-15pe">
        <FooterLinksV2 />
      </div>
    </>
  );
}

export default SuccessPayment;
