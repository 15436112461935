import React from 'react'

const TnC = () => {
  return (
    <div className="flex flex-col h-full gap-2 p-4 md:px-12 md:pt-10">
      <div className="text-white rounded-2xl bg-dark-bl-clr">
        <div className="p-8">
          <h1 className="mb-4 text-2xl font-bold">Terms & Conditions</h1>
          <p className="text-sm">Last updated on 25-09-2024 14:03:15</p>
          <p className="mt-4">
            These Terms and Conditions, along with privacy policy or other terms (“Terms”) constitute a binding
            agreement by and between ARPIT AGARWAL, ( “Website Owner” or “we” or “us” or “our”) and you
            (“you” or “your”) and relate to your use of our website, goods (as applicable) or services (as applicable)
            (collectively, “Services”).
          </p>
          <p className="mt-4">
            By using our website and availing the Services, you agree that you have read and accepted these Terms
            (including the Privacy Policy). We reserve the right to modify these Terms at any time and without
            assigning any reason. It is your responsibility to periodically review these Terms to stay informed of updates.
          </p>
          <p className="mt-4">
            The use of this website or availing of our Services is subject to the following terms of use:
          </p>
          <ul className="mt-2 list-disc list-inside">
            <li className="mt-2">
              To access and use the Services, you agree to provide true, accurate, and complete information to us during and after registration.
            </li>
            <li className="mt-2">
              Neither we nor any third parties provide any warranty as to the accuracy, timeliness, or performance of the information on this website.
            </li>
            <li className="mt-2">
              Your use of our Services and the website is solely at your own risk. You must ensure that the Services meet your requirements.
            </li>
            <li className="mt-2">
              The contents of the Website and the Services are proprietary to Us, and you will not have any authority to claim any intellectual property rights.
            </li>
            <li className="mt-2">
              Unauthorized use of the Website or the Services may lead to legal action as per these Terms or applicable laws.
            </li>
            <li className="mt-2">
              You agree to pay us the charges associated with availing the Services.
            </li>
            <li className="mt-2">
              You agree not to use the website or Services for any unlawful or illegal purpose.
            </li>
            <li className="mt-2">
              You acknowledge that the website and the Services may contain links to third-party websites, which have their own policies.
            </li>
            <li className="mt-2">
              Upon initiating a transaction for availing the Services, you are entering into a legally binding contract with us.
            </li>
            <li className="mt-2">
              You are entitled to claim a refund if we are unable to provide the Service. Refund requests must be raised within the stipulated time.
            </li>
            <li className="mt-2">
              Both parties shall not be liable for any failure to perform due to a force majeure event.
            </li>
            <li className="mt-2">
              These Terms shall be governed by the laws of India, with disputes subject to the courts in BANGALORE NORTH, Karnataka.
            </li>
          </ul>
          <p className="mt-4">
            All concerns or communications relating to these Terms must be communicated using the contact information provided on this website.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TnC;
