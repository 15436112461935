import moment from "moment";
import React, { useEffect, useState } from "react";
import { toCaptionCase } from "../../utils/methods";

const calculateStrokeDasharray = score => {
  const maxScore = 900; // Assuming the max score is 900
  const normalizedScore = (score / maxScore) * 100;
  return `${normalizedScore}, 100`;
};

const CreditScoreCard = ({ creditScore, lastUpdated = "", scoreHealth }) => {
  const [scoreFlag, setScoreFlag] = useState(false);
  const count = 3;
  let redFlag = 0;
  const [rotateSvg, setRotateSvg] = useState(false);

  const handleClick = () => {
    setRotateSvg(true); // Add rotation class
    setTimeout(() => {
      setRotateSvg(false); // Remove rotation class after 2 seconds
    }, 2000);
  };
  // useEffect(() => {
  //   if (rotateSvg) {
  //     setTimeout(() => {
  //       setRotateSvg(false); // Remove rotation class after 2 seconds
  //     }, 2000);
  //   }
  // }, [rotateSvg]);
  return (
    <div className="max-w-sm p-4 px-6 text-white rounded-xl min-w-35p">
      <div className="pl-2 text-left">
        <p className="font-normal text-white text-14">Score Health</p>
        <h2 className="text-17 font-700">
          {/* {creditScore > 750
            ? "Excellent"
            : creditScore > 650
            ? "Average"
            : "Poor"} */}
          {toCaptionCase(scoreHealth)}
        </h2>
      </div>

      <div className="flex items-center justify-center my-6">
        <div className="relative">
          <svg className="w-40 h-40" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <path
              className="text-gray-700"
              strokeLinecap="round"
              strokeWidth="3"
              strokeDasharray="100, 100"
              fill="none"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
              className="text-yellow-400"
              strokeLinecap="round"
              strokeWidth="3"
              strokeDasharray={calculateStrokeDasharray(creditScore)}
              fill="none"
              stroke="currentColor"
              d="M18 2.0845
                 a 15.9155 15.9155 0 0 1 0 31.831
                 a 15.9155 15.9155 0 0 1 0 -31.831"
            />
          </svg>
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <span className="text-4xl font-700">{creditScore}</span>
            <span className="font-normal text-gray-400 text-12">Your credit score</span>
          </div>
        </div>
      </div>

      {/* Button to Get Latest Score */}
      <button
        onClick={() => {
          redFlag++;
          setRotateSvg(true);
          if (redFlag > count || redFlag === count) {
            setScoreFlag(true);
          }
        }}
        // disabled={rotateSvg}
        className="flex items-center justify-center w-full gap-1 p-3 font-semibold text-black rounded-lg cursor-pointer text-12 gradient-btn"
      >
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={rotateSvg ? "rotate-svg" : ""}
        >
          <g clip-path="url(#clip0_8_618)">
            <path d="M12 6.5H15V3.5" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M12.2747 12C11.4884 12.7419 10.501 13.2356 9.43577 13.4196C8.37053 13.6035 7.27471 13.4694 6.28523 13.0341C5.29574 12.5988 4.45648 11.8816 3.87229 10.972C3.28811 10.0625 2.98491 9.00092 3.00058 7.92003C3.01625 6.83914 3.35009 5.78684 3.9604 4.8946C4.57071 4.00236 5.43041 3.30975 6.43209 2.90331C7.43378 2.49687 8.53303 2.39463 9.59249 2.60935C10.652 2.82408 11.6246 3.34625 12.389 4.11062L14.9997 6.5"
              stroke="black"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_8_618">
              <rect width="16" height="16" fill="white" transform="translate(0.5)" />
            </clipPath>
          </defs>
        </svg>
        <span>Get Latest Score</span>
      </button>
      {scoreFlag ? (
        <p className="mt-4 font-normal text-center text-12 text-pinkV1">Stay Tuned: Score Update Coming in One Week!</p>
      ) : null}

      {/* Date Updated */}
      {lastUpdated ? (
        <p className="mt-4 font-normal text-center text-12">
          Updated on <span className="font-bold text-white text-12">{moment(lastUpdated || "").format("DD-MM-YYYY")}</span>
        </p>
      ) : null}
    </div>
  );
};

export default CreditScoreCard;
