import React from "react";
import AdviceCard from "./AdviceCard";

// const data = [
//   {
//     type: "High Unsecured loans",
//     videoUrl: "",
//     description:
//       "sjnadsjndjas dasjkdas  asdhjasjhdbas asdbsajdhas ashdbjsajb sadbhasbjd sdahbjsajbd dasbjbjhdas",
//   },
//   {
//     type: "High Unsecured loans",
//     videoUrl: "",
//     description:
//       "sjnadsjndjas dasjkdas  asdhjasjhdbas asdbsajdhas ashdbjsajb sadbhasbjd sdahbjsajbd dasbjbjhdas",
//   },
// ];

function AdviceComp({data}) {
  return (
    <div className="flex flex-col gap-3 p-4 text-white rounded-2xl bg-dark-bl-clr">
      <div className="text-17 font-700">
        Long Term Advice{" "}
        <span className="font-semibold text-12 text-customGold">
          {data?.length} actions
        </span>
      </div>
      <div className="flex flex-col gap-2 items-center w-full">
        {data?.map((el) => {
          return <AdviceCard data={el} />;
        })}
      </div>
    </div>
  );
}

export default AdviceComp;
