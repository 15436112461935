import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserScore } from "./containers/UserScore/UserScore";
import Login from "./containers/Login";
import PrivateRoute from "./Components/PrivateRoutes";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { HomePage } from "./containers/HomePage";
import AddUserDetails from "./containers/UserDetails/AddUserDetails";
import AddUserDetailsV2 from "./containers/UserDetails/AddUserDetailsV2";
import { ProfilePage } from "./containers/ProfilePage";
import Privacy from "./containers/Privacy";
import RefundPolicy from "./containers/RefundPolicy";
import TnC from "./containers/TnC";
import ContactUs from "./containers/ContactUs";
import SuccessPayment from "./Components/SuccessPayment";
import FailurePayment from "./Components/Failure";
import Processing from "./Components/Processing";
import AppLinks from "./Components/AppLinks";

const AppRoutes = () => {
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    // Retrieve the injected token from React Native
    try {
      if (typeof window !== "undefined") {
        const token = window?.userToken ? window?.userToken : null;
        if (token) {
          localStorage.setItem("userToken", JSON.stringify(token));
          setAuthToken(token);
          console.log("Auth Token from React Native:", token);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <Router>
      {/* <Header /> */}
      {/* <GoogleAnalytics /> */}
      <Routes>
        {/* Public route */}
        <Route path="/landing/:lang" element={<HomePage />} />
        <Route path="/landing" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TnC />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/success" element={<SuccessPayment />} />
        <Route path="/failure" element={<FailurePayment />} />
        <Route path="/processing" element={<Processing />} />
        <Route path="/d/user" element={<AddUserDetails />} />
        <Route path="/user" element={<AddUserDetailsV2 />} />
        <Route path="/app-links" element={<AppLinks />} />

        {/* Private route */}
        <Route
          path="/"
          element={
            // <PrivateRoute>
            <AddUserDetailsV2 />
            // </PrivateRoute>
          }
        />

        <Route
          path="/score"
          element={
            <PrivateRoute>
              <UserScore />
            </PrivateRoute>
          }
        />

        {/* <Route
          path="/home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        /> */}

        <Route
          path="/user"
          element={
            <PrivateRoute>
              <AddUserDetailsV2 />
            </PrivateRoute>
          }
        />
        <Route
          path="/d/user"
          element={
            <PrivateRoute>
              <AddUserDetails />
            </PrivateRoute>
          }
        />

        {/* Add more private routes like this */}
        {/* <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} /> */}
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
};

export default AppRoutes;
