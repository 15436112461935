// src/components/Header.js
import React from "react";
import { Link } from "react-router-dom";

const Header = ({ userData }) => {
  function getIndianGreeting() {
    // Get current time in UTC and add 5 hours 30 minutes for IST
    const currentIST = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const currentHour = new Date(currentIST).getHours(); // Get the hour in IST
  
    if (currentHour >= 5 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Good Afternoon";
    } else if (currentHour >= 17 && currentHour < 21) {
      return "Good Evening";
    } else {
      return "Good Night";
    }
  }
  
  return (
    <header className="w-full text-white">
      <nav className="flex items-center justify-between px-6 py-2">
        <div>
          <div className="font-semibold text-12">{getIndianGreeting()}</div>
          <div className="text-22 font-700">{userData?.firstName}</div>
        </div>
        <Link to="/profile">
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_94_17)">
            <path
              d="M21.5 15.5C21.5 16.5878 21.1774 17.6512 20.5731 18.5556C19.9687 19.4601 19.1098 20.1651 18.1048 20.5813C17.0998 20.9976 15.9939 21.1065 14.927 20.8943C13.8601 20.6821 12.8801 20.1583 12.1109 19.3891C11.3417 18.6199 10.8179 17.6399 10.6057 16.573C10.3935 15.5061 10.5024 14.4002 10.9187 13.3952C11.335 12.3902 12.0399 11.5313 12.9444 10.9269C13.8488 10.3226 14.9122 10 16 10C17.4582 10.0017 18.8562 10.5816 19.8873 11.6127C20.9184 12.6438 21.4984 14.0418 21.5 15.5ZM29 16.5C29 19.0712 28.2376 21.5846 26.8091 23.7224C25.3807 25.8603 23.3503 27.5265 20.9749 28.5104C18.5995 29.4944 15.9856 29.7518 13.4638 29.2502C10.9421 28.7486 8.6257 27.5105 6.80762 25.6924C4.98953 23.8743 3.75141 21.5579 3.2498 19.0362C2.74819 16.5144 3.00563 13.9006 3.98957 11.5251C4.97351 9.14968 6.63975 7.11935 8.77759 5.6909C10.9154 4.26244 13.4288 3.5 16 3.5C19.4467 3.50364 22.7512 4.87445 25.1884 7.31163C27.6256 9.74882 28.9964 13.0533 29 16.5ZM27 16.5C26.9984 15.0194 26.6982 13.5544 26.1174 12.1924C25.5366 10.8305 24.6871 9.59974 23.6198 8.57367C22.5524 7.54759 21.289 6.74732 19.9053 6.2207C18.5215 5.69408 17.0457 5.45194 15.5663 5.50875C9.67876 5.73625 4.98376 10.64 5.00001 16.5312C5.00565 19.2132 5.99478 21.7998 7.78001 23.8013C8.50703 22.7468 9.43056 21.8423 10.5 21.1375C10.5912 21.0773 10.6996 21.0486 10.8086 21.0558C10.9177 21.063 11.0213 21.1058 11.1038 21.1775C12.4627 22.3529 14.1995 22.9998 15.9963 22.9998C17.793 22.9998 19.5298 22.3529 20.8888 21.1775C20.9712 21.1058 21.0749 21.063 21.1839 21.0558C21.2929 21.0486 21.4013 21.0773 21.4925 21.1375C22.5633 21.842 23.4881 22.7464 24.2163 23.8013C26.0103 21.7925 27.0013 19.1932 27 16.5Z"
              fill="#DFD9D9"
            />
          </g>
          <defs>
            <clipPath id="clip0_94_17">
              <rect
                width="32"
                height="32"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
