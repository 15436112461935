// src/actions/actionTypes.js
export const SENT_OTP_REQ = "SENT_OTP_REQ";
export const SENT_OTP_SUCCESS = "SENT_OTP_SUCCESS";
export const SENT_OTP_FAILURE = "SENT_OTP_FAILURE";

export const VERIFY_OTP_REQ = "VERIFY_OTP_REQ";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const FETCH_USER_DATA_REQ = "FETCH_USER_DATA_REQ";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILURE = "FETCH_USER_DATA_FAILURE";

export const POST_USER_DETAILS_REQ = "POST_USER_DETAILS_REQ";
export const POST_USER_DETAILS_SUCCESS = "POST_USER_DETAILS_SUCCESS";
export const POST_USER_DETAILS_FAILURE = "POST_USER_DETAILS_FAILURE";

export const POST_PAYMENT_REQ = "POST_PAYMENT_REQ";
export const POST_PAYMENT_SUCCESS = "POST_PAYMENT_SUCCESS";
export const POST_PAYMENT_FAILURE = "POST_PAYMENT_FAILURE";

export const GET_PAYMENT_REQ = "GET_PAYMENT_REQ";
export const GET_PAYMENT_SUCCESS = "GET_PAYMENT_SUCCESS";
export const GET_PAYMENT_FAILURE = "GET_PAYMENT_FAILURE";

export const CHECK_PROFILE_STATUS_REQ = "CHECK_PROFILE_STATUS_REQ";
export const CHECK_PROFILE_STATUS_SUCCESS = "CHECK_PROFILE_STATUS_SUCCESS";
export const CHECK_PROFILE_STATUS_FAILED = "CHECK_PROFILE_STATUS_FAILED";

export const POST_USER_ONBOARDING_REQ = "POST_USER_ONBOARDING_REQ";
export const POST_USER_ONBOARDING_SUCCESS = "POST_USER_ONBOARDING_SUCCESS";
export const POST_USER_ONBOARDING_FAILURE = "POST_USER_ONBOARDING_FAILURE";

export const GET_USER_PAYMENT_REQ = "GET_USER_PAYMENT_REQ";
export const GET_USER_PAYMENT_SUCCESS = "GET_USER_PAYMENT_SUCCESS";
export const GET_USER_PAYMENT_FAILURE = "GET_USER_PAYMENT_FAILURE";
