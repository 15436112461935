import React from "react";
import ImproveCard from "./ImproveCard";

// const data = [
//   {
//     company: "Kotak",
//     maskedCardNumber: "912837***",
//     missedEMI: "3",
//     viewEmis: [{ id: 1, value: "" }],
//     amount: 21341,
//     type: "missed",
//   },
//   {
//     company: "Kotak",
//     maskedCardNumber: "912837***",
//     missedEMI: "3",
//     viewEmis: [{ id: 1, value: "" }],
//     amount: 21341,
//     type: "upcoming",
//   },
//   {
//     company: "Kotak",
//     maskedCardNumber: "912837***",
//     missedEMI: "3",
//     viewEmis: [{ id: 1, value: "" }],
//     amount: 21341,
//     type: "high utilization",
//     risk: "",
//   },
// ];

function ImproveCredit({data}) {
  return (
    <div className="flex flex-col gap-3 p-4 text-white rounded-2xl bg-dark-bl-clr">
      <div className="text-17 font-700">
        Improve credit Score{" "}
        <span className="font-semibold text-12 text-customGold">
          {data?.length} actions
        </span>
      </div>
      <div className="flex flex-col gap-2">
        {data?.map((el) => {
          return <ImproveCard data={el} />;
        })}
      </div>
    </div>
  );
}

export default ImproveCredit;
