// src/reducers/userReducer.js
import {
  SENT_OTP_REQ,
  SENT_OTP_SUCCESS,
  SENT_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  POST_USER_DETAILS_SUCCESS,
  FETCH_USER_DATA_SUCCESS,
  POST_USER_ONBOARDING_SUCCESS,
  CHECK_PROFILE_STATUS_SUCCESS,
} from "./../actionTypes/common";

const initialState = {
  loading: false,
  user: null,
  error: null,
  userScoreData: null,
  token: "",
  userData:
    typeof localStorage !== "undefined"
      ? JSON.parse(localStorage.getItem("userData"))
      : {},
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SENT_OTP_REQ:
      return { ...state, loading: true };
    case SENT_OTP_SUCCESS:
      console.log("action", action);
      return { ...state, loading: false, user: action.payload };
    case SENT_OTP_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case VERIFY_OTP_SUCCESS:
      localStorage.setItem("userToken", JSON.stringify(action.payload));
      try {
        if (typeof window !== "undefined" && window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ userToken: action?.payload })
          );
        }
      } catch (e) {
        console.log(e);
      }
      return { ...state, token: action.payload };
    case FETCH_USER_DATA_SUCCESS:
      console.log("chekdata", action);
      return { ...state, userScoreData: action.payload };
    case POST_USER_DETAILS_SUCCESS:
      localStorage.setItem("userData", JSON.stringify(action.payload));
      return { ...state, userData: action.payload };
    case CHECK_PROFILE_STATUS_SUCCESS:
      localStorage.setItem("userData", JSON.stringify(action.payload));
      return { ...state, userData: action.payload };
    case POST_USER_ONBOARDING_SUCCESS:
      localStorage.setItem("userToken", JSON.stringify(action.payload?.token));
      try {
        if (typeof window !== "undefined" && window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ userToken: action?.payload?.token })
          );
        }
      } catch (e) {
        console.log(e);
      }
      return { ...state, token: action?.payload?.token };
    default:
      return state;
  }
};

export default commonReducer;
