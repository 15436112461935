import React from "react";
import CibilScoreComponent from "../Components/CIbilScoreText";
import LandingBG from "./../Images/landingBg.png";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { landingContent } from "./landing";
import { useSelector } from "react-redux";

const highlightText = (text, wordsToHighlight) => {
  // Split the sentence by spaces
  const parts = text.split(" ");

  return parts?.map((part, index) => {
    if (wordsToHighlight?.includes(part)) {
      return (
        <span key={index} className="font-bold">
          {part}{" "}
        </span>
      );
    }
    // Otherwise, just return the word
    return <span key={index}>{part} </span>;
  });
};

export const HomePage = () => {
  const { lang } = useParams();
  const currentContent = landingContent[lang];
  const { userData } = useSelector(state => state.common)
  const navigate=useNavigate()

  const handleProceed = () => {
    if (userData.id) {
      navigate('/score')
    } else {
      navigate('/user')
    }
  }

  if (!currentContent) {
    return <Navigate to="/landing/en" />; // Redirect to '/en' if the route doesn't match
  }
  return (
    <div className="flex flex-col h-full gap-2 p-4 md:relative pb-14 bg-bdhao-clr md:mx-15pe md:mt-10 md:rounded-2xl md:bg-dark-bl-clr md:justify-center md:items-center ">
      <CibilScoreComponent heading={currentContent?.header} />
      <img className="px-12 py-2 md:w-50pe" style={{ minWidth: "130px", minHeight: "100px" }} src={LandingBG} alt="landing" />
      <h2 className="p-2 mb-6 leading-7 text-white font-700 w-75p font-22 md:text-center">
        {currentContent?.plan_title.map((segment, index) => {
          if (segment?.highlight) {
            return (
              <span key={index} className="text-customGold">
                {segment?.highlight}
              </span>
            );
          }
          return <span key={index}>{segment.text}</span>;
        })}
      </h2>
      <ul className="space-y-4">
        {currentContent?.features?.map((feature, index) => (
          <li key={index} className="flex items-center p-2 space-x-2 text-white rounded-lg bg-dark-bl-clr">
            <div
              style={{ minWidth: "48px", height: "48px" }}
              className="flex items-center justify-center p-2 bg-black rounded-lg"
            >
              <img src={feature?.icon} />
            </div>
            <div>
              {/* <h3 className="text-lg font-semibold">{feature?.title}</h3> */}
              <p className="text-white text-14 font-400">{highlightText(feature?.description, feature?.wordsToHighlight)} </p>
            </div>
          </li>
        ))}
      </ul>
      <footer onClick={handleProceed} className="fixed flex items-center justify-center py-2 font-semibold cursor-pointer left-3 right-3 bottom-3 gradient-btn rounded-3xl text-17 md:absolute md:left-12 md:right-12">
        Proceed
      </footer>
    </div>
  );
};
