import MagGlass from "./../Images/Union.svg";
import Chats from "./../Images/Chats.svg";
import Speedometer from "./../Images/Speedometer.svg";
import Video from "./../Images/Video.svg";
import Warning from "./../Images/Warning.svg";

export const landingContent = {
  en: {
    header: "Increase your CIBIL score to 750+",
    plan_title: [{ text: "Credit Score improvement plan at " }, { highlight: "just ₹99" }],
    features: [
      {
        icon: MagGlass,
        title: "Latest Credit Score",
        description: "Get detailed analysis of your Credit Report.",
        wordsToHighlight: ["detailed", "Credit Report"]
      },
      {
        icon: Warning,
        title: "Identify Fake Loan",
        description: "Identify Fake Loan accounts and raise.",
        wordsToHighlight: ["detailed", "Credit Report"]
      },
      {
        icon: Video,
        title: "Video guidance",
        description: "Watch Video guidance from CIBIL Expert to improve score.",
        wordsToHighlight: []
      },
      {
        icon: Chats,
        title: "Chat 24x7",
        description: "Chat 24x7 with CIBIL Expert to ask any questions.",
        wordsToHighlight: []
      },
      {
        icon: Speedometer,
        title: "Tips and Instructions",
        description: "Follow Tips and Instructions in video to improve CIBIL to 750+.",
        wordsToHighlight: []
      }
    ]
  },
  in: {
    header: "अपना CIBIL स्कोर 750+ तक बढ़ाएं",
    plan_title: [{ text: "क्रेडिट स्कोर सुधार योजना " }, { highlight: "मात्र ₹99" }],
    features: [
      {
        icon: MagGlass,
        title: "नवीनतम क्रेडिट स्कोर",
        description: "अपनी क्रेडिट रिपोर्ट का विस्तृत विश्लेषण प्राप्त करें।",
        wordsToHighlight: []
      },
      {
        icon: Warning,
        title: "फर्जी ऋण की पहचान करें",
        description: "फर्जी ऋण खाते की पहचान करें और शिकायत दर्ज करें।",
        wordsToHighlight: []
      },
      {
        icon: Video,
        title: "वीडियो मार्गदर्शन",
        description: "CIBIL विशेषज्ञ से वीडियो मार्गदर्शन देखें और स्कोर सुधारें।",
        wordsToHighlight: []
      },
      {
        icon: Chats,
        title: "24x7 चैट करें",
        description: "किसी भी प्रश्न को पूछने के लिए CIBIL विशेषज्ञ से 24x7 चैट करें।",
        wordsToHighlight: []
      },
      {
        icon: Speedometer,
        title: "टिप्स और निर्देश",
        description: "वीडियो में दिए गए टिप्स और निर्देशों का पालन करें और CIBIL को 750+ तक सुधारें।",
        wordsToHighlight: []
      }
    ]
  }
};
