import React from "react";

const CibilScoreComponent = ({ heading }) => {
  return (
    <div className="p-2 md:text-center">
      <h1 className="text-transparent w-87p text-22 font-700 bg-clip-text bg-custom-gradient md:w-full">{heading}</h1>
      {/* <p className="mt-4 text-3xl font-medium text-gray-300">
        score to 750+
      </p> */}
    </div>
  );
};

export default CibilScoreComponent;
