import React from "react";
import CreditScoreCard from "./ScoreMeter";

export const CreditScore = ({creditScore,scoreHealth,lastUpdated}) => {
  return (
    <div className="flex justify-center p-4 rounded-2xl bg-dark-bl-clr">
      <CreditScoreCard creditScore={creditScore} scoreHealth={scoreHealth} lastUpdated={lastUpdated} />
    </div>
  );
};
