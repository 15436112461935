import React, { useEffect, useLayoutEffect, useState } from "react";
import LogoNew from "./../Images/logoNew1.png";

function AppLinks({}) {
  const [loading, setLoading] = useState(false);
  useLayoutEffect(() => {
    const body = document.body;
    body.style.background = "white";
  }, []);
  const handleDownload = () => {
    setLoading(true);
    window.location.href = "https://bit.ly/sbapkurl";
    // setLoading(false);
  };
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [loading]);
  const appPointers = [
    "1. Download the app",
    "2. Accept warning, click download anyway ",
    "3. Give permission for unknown sources",
    "4. Accept scan app & install",
  ];
  return (
    <>
      <header className="fixed top-0 left-0 p-3 w-full bg-white z-100">
        <img style={{ width: "160px", height: "51px" }} src={LogoNew} alt="" />
      </header>
      <div className="flex relative flex-col px-6 py-14 h-screen justify-center rounded-2xl po:items-start md:h-1/2 md:m-10p md:bg-blueV1 pt-[80px]">
        {/* <div className="flex flex-col gap-10 justify-between pt-20 w-full max-w-md h-full rounded-2xl md:bg-transparent"> */}
        <div className="text-[#05B42C] font-[700] text-29 ">
          Your 750+ Score Improvement Journey Begins Now
        </div>
        <div className="my-8 w-full">
          <button
            type="submit"
            className={`px-4 py-3 flex gap-1 rounded-26p items-center justify-center cursor-pointer font-semibold text-black  ${
              loading
                ? "bg-gray-300 pointer-events-none backdrop-greyscale"
                : "right-5 left-5 rounded-2xl gradient-btn"
            } w-full left-5 rounded-26p z-5`}
            onClick={handleDownload}
            // disabled={!isSdkReady} // Disable button until SDK is ready
          >
            {loading ? (
              <svg
                className="mr-3 -ml-1 w-5 h-5 text-white animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="#000000"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : null}
            Download App
          </button>
        </div>
        <div
          style={{ border: "1px solid #dfdfdf" }}
          className="p-3 mt-6 w-full rounded-20 md:bg-white"
        >
          <div className="font-bold text-17">Trouble Installing The App?</div>
          <ul className="flex flex-col gap-4 my-2">
            {appPointers.map((el) => (
              <li key={el} className="p-1.2p bg-[#ebf3ff] rounded-12">
                {el}
              </li>
            ))}
          </ul>
        </div>
        <a
          href="https://wa.me/7891764101" // Replace with phone number
          target="_blank"
          rel="noopener noreferrer"
          className="flex gap-2 justify-center items-center mt-4 w-full font-semibold cursor-pointer text-17"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            width="24"
            height="24"
            fill="#25D366"
            // style={{ marginRight: "8px" }}
          >
            <path d="M16 0C7.16 0 0 7.16 0 16c0 2.96.8 5.76 2.2 8.2L.16 32l8.36-2.2C10.24 31.2 13.08 32 16 32c8.84 0 16-7.16 16-16S24.84 0 16 0zm0 29.52c-2.64 0-5.12-.68-7.32-1.96l-.52-.32-5.96 1.56 1.56-5.96-.32-.52C2.68 21.12 2 18.64 2 16 2 8.28 8.28 2 16 2s14 6.28 14 14-6.28 14-14 14zm7.4-10.8c-.4-.2-2.32-1.16-2.68-1.28-.36-.12-.64-.2-.92.2-.28.4-1.08 1.28-1.32 1.56-.24.28-.48.32-.88.12-.4-.2-1.68-.6-3.2-1.88-1.18-1.04-1.96-2.32-2.2-2.72-.24-.4-.02-.64.18-.84.2-.2.4-.52.6-.8.2-.28.28-.48.4-.8.12-.4.04-.72-.04-.96-.12-.2-.92-2.24-1.24-3.04-.32-.8-.64-.68-.88-.68h-.76c-.28 0-.76.08-1.16.52-.4.44-1.52 1.48-1.52 3.6s1.56 4.16 1.8 4.44c.24.28 3.08 4.72 7.48 6.6.72.32 1.28.52 1.72.68.72.28 1.36.24 1.88.16.56-.08 1.76-.72 2-1.4.24-.68.24-1.28.16-1.4-.08-.12-.36-.2-.76-.4z" />
          </svg>
          <span> Connect on WhatsApp</span>
        </a>
        {/* </div> */}
      </div>
    </>
  );
}

export default AppLinks;
