import React, { useEffect } from "react";
import ProcessingLot from "./../Images/Processing.json";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPaymentReq } from "../redux/actions/common";
import Lottie from "react-lottie-player";

function Processing() {
  const getOrderIdFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("order_id"); // or whatever the parameter is called in your URL
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const orderId = getOrderIdFromURL(); // Extract orderId from URL
    if (orderId) {
      // Call API to check the payment status
      checkPaymentStatus(orderId);
    }
    try {
      if (typeof window !== "undefined" && window.fbq) {
        window.fbq("track", "PaymentSuccess");
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const checkPaymentStatus = (orderId) => {
    dispatch(
      getPaymentReq({
        orderId: orderId,
        successAction: (res) => {
          const paymentDetails = res;
          let orderStatus;
          if (paymentDetails.order_status === "PAID") {
            orderStatus = "Success";
            try {
              if (typeof window !== "undefined" && window.fbq) {
                window.fbq("track", "PaymentSuccess");
              }
            } catch (e) {
              console.error(e);
            }
            navigate("/success");
          } else {
            try {
              if (typeof window !== "undefined" && window?.fbq) {
                window.fbq("track", "PaymentFailure");
              }
            } catch (e) {
              console.error(e);
            }
            navigate("/failure");
            // orderStatus = "Failure";
            // Redirect or update the UI to show failure
          }
        },
      })
    );
  };
  return (
    <div className="flex flex-col gap-1 justify-center items-center p-4 h-screen">
      <div className="w-1/2 text-center text-white text-17 font-700">
        Processing Payment
      </div>
      <div className="w-1/2 text-center text-white text-17 font-700">
        Please wait
      </div>
      <Lottie
        loop
        animationData={ProcessingLot}
        play
        style={{ width: 150, height: 150 }}
      />
    </div>
  );
}

export default Processing;
