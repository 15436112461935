// src/reducers/index.js
import { combineReducers } from "redux";
// import userReducer from "./userReducer"; // Example reducer
import commonReducer from "./commonReducer";

const rootReducer = combineReducers({
  common: commonReducer,
});

export default rootReducer;
