import React from "react";
import { toCaptionCase } from "../../utils/methods";
import moment from "moment";

function LoanCard({ data }) {
  return (
    <div  style={{ minWidth: "205px" }}
   className="flex flex-col justify-between p-4 font-bold text-white rounded-2xl w-fit bg-card-loan">
      <div className="py-0.3p px-2 text-def-clr rounded-xl w-max bg-loan-type">
        {toCaptionCase(data?.loantype)}
      </div>
      <div className="font-700 text-17 w-fit">{data?.company}</div>
      <div className="font-semibold leading-3 text-white opacity-60 text-10">
        {data?.maskedCardNumber}
      </div>
      <div className="font-normal text-new-clr-loan">
        Due Date: <strong>{moment(data?.dueDate).format("DD-MM-YYYY")}</strong>
      </div>
      <div>EMI: {data?.emiValue}</div>
    </div>
  );
}

export default LoanCard;
