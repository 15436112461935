import React from "react";
import { toCaptionCase, toCurrencyWithoutSpace } from "../../utils/methods";
import moment from "moment";

function ImproveCard({ data }) {
  console.log(data);
  return (
    <div className="flex flex-col gap-2 p-4 text-white rounded-2xl bg-card-loan">
      <div className="flex justify-between items-start">
        <div className="flex flex-col gap-1">
          <div className="font-700 text-17">{data.company}</div>
          <div className="font-semibold leading-3 text-white opacity-60 text-10">
            {data.maskedCardNumber}
          </div>
        </div>
        <div className="flex flex-col gap-1 items-end">
          <div className="p-0.3 px-1 rounded text-black font-700 text-17 bg-improve-type-blue">
            {toCaptionCase(data.type)}
          </div>
          {data?.viewEmis?.length > 0 && (
            <div className="font-semibold leading-3 text-white opacity-60 text-14">
              View All
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="font-normal text-new-clr-loan">
          Due Date: <strong>{moment(data.dueDate).format("DD-MM-YYYY")}</strong>
        </div>
        <div>{toCurrencyWithoutSpace(data.amount)}</div>
      </div>
    </div>
  );
}

export default ImproveCard;
