import React, { useEffect, useLayoutEffect, useState } from "react";
import { CreditScore } from "../../Components/CreditScore/CreditScore";
import CarousalLoans from "../../Components/CarousalLoans/CarousalLoans";
import ImproveCredit from "../../Components/ImproveCredit/ImproveCredit";
import AdviceComp from "../../Components/AdviceComp/AdviceComp";
import VideoCard from "../../Components/VideoCard/VideoCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDataReq,
  getUserProfileReq,
} from "../../redux/actions/common";
import Header from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import ProcessingLot from "./../../Images/Processing.json";
import FooterLinks from "../FooterLinks";

export const UserScore = () => {
  const dispatch = useDispatch();
  const { userData, userScoreData } = useSelector((state) => state.common);
  const [loading, setLoading] = useState(true);
  console.log("user", userData, userScoreData);
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const body = document.body;
    body.style.background = "#202023";
  }, []);

  useEffect(() => {
    dispatch(
      getUserProfileReq({ successAction: () => {}, errorAction: () => {} })
    );
    dispatch(fetchUserDataReq());
    // dispatch(
    //   getUserProfileReq({
    //     successAction: res => {
    //       if (res?.paymentStartedOn && res?.paymentStatus !== "PAID") {
    //         navigate("/user");
    //         setLoading(false);
    //       } else {
    //         dispatch(fetchUserDataReq());
    //         setLoading(false);
    //       }
    //     },
    //     errorAction: () => {
    //       navigate("/user");
    //       setLoading(false);
    //     }
    //   })
    // );
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, []);
  if (loading) {
    return (
      <div className="flex flex-col gap-1 justify-center items-center p-4 h-screen">
        <Lottie
          loop
          animationData={ProcessingLot}
          play
          style={{ width: 150, height: 150 }}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-4 h-full md:px-12pe">
      <Header userData={userData} />
      <VideoCard />
      <CreditScore
        scoreHealth={userScoreData?.scoreHealth}
        creditScore={userScoreData?.creditScore}
        lastUpdated={userScoreData?.lastUpdatedOn}
      />
      {userScoreData?.activeLoans?.length > 0 ? (
        <CarousalLoans data={userScoreData?.activeLoans} />
      ) : null}
      {userScoreData?.improveSteps?.length > 0 ? (
        <ImproveCredit data={userScoreData?.improveSteps} />
      ) : null}
      {userScoreData?.adviceSteps.length > 0 ? (
        <AdviceComp data={userScoreData?.adviceSteps} />
      ) : null}
      <FooterLinks />
    </div>
  );
};
