import React, { useCallback, useEffect } from "react";
import Failure from "./../Images/Failure.json"; // Ensure this path is correct
// import LottiePlayer from "./LottiePlayer"; // Ensure this path is correct
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
// import { load } from "@cashfreepayments/cashfree-js";
import { useDispatch, useSelector } from "react-redux";
import { postPaymentReq } from "../redux/actions/common";
import FooterLinksV2 from "../containers/FooterLinks";
import { useRazorpay } from "react-razorpay";

function FailurePayment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.common);
  const { Razorpay } = useRazorpay();

  //   useEffect(() => {
  //     setTimeout(() => {
  //       navigate("/user");
  //     }, 5000);
  //   }, []);
  //   const handlePayment = () => {
  //     navigate("/user");
  //   };
  // let cashfree;
  // var initializeSDK = async function () {
  //   cashfree = await load({
  //     mode: "production",
  //   });
  // };
  // initializeSDK();
  useEffect(() => {
    try {
      if (typeof window !== "undefined" && window?.fbq) {
        window.fbq("track", "PaymentFailure");
      }
    } catch (e) {
      console.error(e);
    }
  }, []);
  const handleRazorPayment = useCallback(
    async (session, data) => {
      const order = {
        //   amount: 500,
        currency: "INR",
        // receipt: "qwsaq1",
      };
      const options = {
        key: "rzp_live_AGkRfLZcvYYSI4",
        amount: 99 * 100,
        currency: "INR",
        name: "Score Badhao",
        // description: "Test Transaction",
        order_id: session?.session_id,
        callback_url: "https://yzq5ql-3÷000.csb.app/processing",
        handler: (res) => {
          // debugger;
          // swal({
          //   title: "Payment Successful",
          //   text: "Your order has been placed!",
          //   icon: "success",
          // });
          try {
            if (typeof window !== "undefined" && window?.fbq) {
              window.fbq("track", "PaymentSuccess");
            }
          } catch (e) {
            console.error(e);
          }
          navigate("/success");
        },
        prefill: {
          name: data?.name || userData.firstName,
          contact: data?.mobileNumber || userData?.mobileNumber,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#F37254",
        },
        method: {
          upi: {
            flow: "collect", // To ensure UPI PSP apps like Google Pay, PhonePe, etc.
            apps: ["google_pay", "phonepe", "paytm"], // Optional: You can specify allowed PSP apps
          },
        },
        googlepay: true,
        modal: {
          ondismiss: function () {
            // swal("Payment was not completed");
            try {
              if (typeof window !== "undefined" && window?.fbq) {
                window.fbq("track", "PaymentFailure");
              }
            } catch (e) {
              console.error(e);
            }
            navigate("/failure");
          },
        },
        // config: {
        //   display: {
        //     blocks: {
        //       banks: {
        //         name: "Pay via UPI",
        //         instruments: [
        //           {
        //             method: "upi",
        //             // providers: ["google_pay", "phonepe"],
        //           },
        //         ],
        //       },
        //     },
        //     sequence: ["block.banks"],
        //     preferences: {
        //       show_default_blocks: false,
        //     },
        //   },
        // },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );
  const handleFailure = () => {
    dispatch(
      postPaymentReq({
        amount: 99,
        details: "Payment for PAN verification",
        successAction: (res) => {
          console.log("Payment session created", res);
          handleRazorPayment(res); // Pass session ID to payment
        },
      })
    );
  };
  return (
    <>
      <div className="flex flex-col gap-3 justify-center items-center p-4 h-screen">
        {/* <LottiePlayer animationData={Success} /> */}
        <div className="w-1/2 text-center text-30 text-pinkV1 font-700">
          Payment Failed
        </div>
        <Lottie
          animationData={Failure}
          play
          style={{ width: 150, height: 150 }}
        />
        {/* <div className="w-1/2 text-center text-white text-17">Please try Again </div> */}
        <button
          type="submit"
          className={`px-4 py-2 text-black rounded cursor-pointer gradient-btn`}
          onClick={handleFailure}
        >
          Please try Again{" "}
        </button>
      </div>
      <div className="md:mx-15pe">
        <FooterLinksV2 />
      </div>
    </>
  );
}

export default FailurePayment;
