import React from "react";

const Privacy = () => {
  return (
    <div className="flex flex-col h-full gap-2 p-4 md:px-12 md:pt-10">
      <div className="text-white rounded-2xl bg-dark-bl-clr">
        <div className="p-8">
          <h1 className="mb-4 text-2xl font-bold text-golden">Privacy Policy</h1>
          <p className="mb-4 text-sm">Last updated on 25-09-2024 14:02:51</p>

          <h2 className="mt-4 text-lg font-semibold">Information You Provide</h2>
          <p className="mt-2">
            To access certain features on our website, we may ask you to provide personal details during pre-registration, such
            as:
          </p>
          <ul className="mt-2 list-disc list-inside">
            <li>Your name</li>
            <li>Email address</li>
            <li>Mobile number</li>
            <li>Postal code</li>
          </ul>
          <p className="mt-2">
            We may request additional information if needed to comply with legal requirements or offer additional services.
            ScoreBadhao may also collect data from authorized third-party providers, such as your credit score, to enhance the
            services provided.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Consent</h2>
          <p className="mt-2">
            By sharing your details or documents on the ScoreBadhao website, you consent to being contacted by ScoreBadhao (and
            its business partners) via SMS, phone, and/or email for service follow-ups, product knowledge sharing, and
            promotional offers related to the website. You also consent to us retrieving your KYC details from relevant
            regulatory portals (e.g., CERSAI CKYC) when necessary.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Automatically Collected Information</h2>
          <p className="mt-2">
            We limit the collection of automatically tracked data to what is essential for the website’s functionality. This may
            include:
          </p>
          <ul className="mt-2 list-disc list-inside">
            <li>IP address</li>
            <li>Browser type</li>
            <li>Time spent on our site</li>
            <li>Pages viewed</li>
          </ul>
          <p className="mt-2">
            Third-party services may be used for automatic data collection, but no personally identifiable information will be
            shared with them unless required by law.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Use of Personal Information</h2>
          <p className="mt-2">
            We do not share your information with third parties for their own marketing purposes. The automatically collected
            data helps us optimize and personalize your experience on the website.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Information Sharing</h2>
          <p className="mt-2">
            Your personal information will not be shared with third parties except when required by law, or for identity
            verification, crime prevention, or legal proceedings. We may also share limited information with partners who assist
            us in providing our services (e.g., KYC verification, document collection). These partners are contractually bound
            to safeguard your data.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Data Security</h2>
          <p className="mt-2">
            We take necessary precautions to secure your personal data, including encryption and access control mechanisms. All
            data is stored in secure servers, with access limited to authorized personnel only.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Data Retention</h2>
          <p className="mt-2">
            We retain your information for as long as necessary to provide services or until your account is terminated. Even
            after termination, anonymized data may be used for analysis and research. We may retain your contact information for
            fraud prevention or legal purposes. If you wish to modify or delete your personal data, or withdraw consent, please
            contact us at support@scorebadhao.com.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Log Files</h2>
          <p className="mt-2">
            We collect log information such as IP addresses, browser types, and timestamps. This data is used to improve website
            functionality and services.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Cookies & Tracking</h2>
          <p className="mt-2">
            ScoreBadhao uses cookies, including those provided by Google Analytics, to enhance your browsing experience. Cookies
            help track user sessions and preferences but do not store personally identifiable information. You can disable
            cookies through your browser settings if you wish, but this may affect your experience on our site.
          </p>

          <h2 className="mt-4 text-lg font-semibold">Grievance Redressal</h2>
          <p className="mt-2">
            For questions, concerns, or grievances related to privacy or security, please reach out to us at
            support@scorebadhao.com.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
