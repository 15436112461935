import React from "react";
import LoanCard from "./LoanCard";

// const data = [
//   {
//     loantype: "personal Loan",
//     company: "Kotak mahindra",
//     maskedCardNumber: "000*****",
//     dueDate: 12412412,
//     emiValue: 12432,
//   },
//   {
//     loantype: "personal Loan",
//     company: "Kotak mahindra",
//     maskedCardNumber: "000*****",
//     dueDate: 12412412,
//     emiValue: 12432,
//   },
//   {
//     loantype: "personal Loan",
//     company: "Kotak mahindra",
//     maskedCardNumber: "000*****",
//     dueDate: 12412412,
//     emiValue: 12432,
//   },
// ];

function CarousalLoans({data}) {
  return (
    <div className="rounded-2xl bg-dark-bl-clr">
      {data?.length? <div className="p-3 pb-2 text-white font-400 text-14">
        Active Loans {data?.length||0}
      </div>:null}
      {data && data?.length > 0 ? (
        <>
          <div
            className={`flex overflow-auto hide-scroll-bar gap-3.5 py-5 pt-3 px-4 banners-content`}
          >
            {data?.map((value, vindex) => (
              // <div
              //   style={{ minWidth: "200px" }}
              //   className="p-4 font-bold text-white rounded-2xl bg-card-loan"
              // >
                <LoanCard data={value} />
              // </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}

export default CarousalLoans;
