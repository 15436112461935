// src/sagas/userSaga.js
import { takeLatest, call, put, select } from "redux-saga/effects";
import {
  sendOtpSuccess,
  sendOtpFailure,
  verifyOtpSuccess,
  verifyOtpFailure,
  postUserDetailsFailure,
  postUserDetailsSuccess,
  fetchUserDataSuccess,
  fetchUserDataFailure,
  postPaymentSuccess,
  postPaymentFailure,
  getPaymentSuccess,
  getPaymentFailure,
  getUserProfileSuccess,
  getUserProfileFailed,
  postUserOnboardingSuccess,
  postUserOnboardingFailure,
  getUserPaymentSuccess,
  getUserPaymentFailure,
} from "./../actions/common";
import {
  fetchUserDataApi,
  getPaymentApi,
  getUserPaymentApi,
  getUserProfileApi,
  postOnBoardingApi,
  postPaymentApi,
  postSendOtpApi,
  postUserDetailsApi,
  postVerifyOtpApi,
} from "./../api/common";
import {
  CHECK_PROFILE_STATUS_REQ,
  FETCH_USER_DATA_REQ,
  GET_PAYMENT_REQ,
  GET_USER_PAYMENT_REQ,
  POST_PAYMENT_REQ,
  POST_USER_DETAILS_REQ,
  POST_USER_ONBOARDING_REQ,
  SENT_OTP_REQ,
  VERIFY_OTP_REQ,
} from "../actionTypes/common";

function* postSendOtpSaga(action) {
  console.log("check123", action);
  try {
    const user = yield call(postSendOtpApi, action.payload);
    console.log("check123", user);
    yield put(sendOtpSuccess());
  } catch (error) {
    console.log("checkerror", error);
    yield put(sendOtpFailure(error));
  }
}

function* postVerifyOtpSaga(action) {
  try {
    const response = yield call(postVerifyOtpApi, action.payload);
    const token = response?.data?.token;
    yield put(
      verifyOtpSuccess(
        typeof token === "string"
          ? response?.data?.token
          : response?.data?.token?.token
      )
    );
    if (typeof token !== "string" && token?.user) {
      yield put(postUserDetailsSuccess(token?.user));
    }
    if (typeof action.payload.successAction === "function") {
      action.payload.successAction(response.data);
    }
  } catch (error) {
    console.log("err", error);
    yield put(verifyOtpFailure(error));
    if (typeof action.payload.errorAction === "function") {
      action.payload.errorAction(error);
    }
  }
}

function* getUserDataSaga(action) {
  const token = yield select((state) => state?.common?.token);
  try {
    const response = yield call(fetchUserDataApi, token);
    yield put(fetchUserDataSuccess(response.data));
  } catch (error) {
    yield put(fetchUserDataFailure(error));
  }
}

function* postUserDetailsSaga(action) {
  const token = yield select((state) => state?.common?.token);
  try {
    const response = yield call(postUserDetailsApi, action.payload, token);
    console.log("userdata", response.data);
    yield put(postUserDetailsSuccess(response.data.user));
    if (typeof action.payload.successAction === "function") {
      action.payload.successAction(response.data);
    }
  } catch (error) {
    yield put(postUserDetailsFailure(error));
    if (typeof action.payload.errorAction === "function") {
      action.payload.errorAction(error);
    }
  }
}

function* postPaymentSaga(action) {
  const token = yield select((state) => state?.common?.token);
  try {
    const response = yield call(postPaymentApi, action.payload, token);
    console.log("userdata", response.data);
    yield put(postPaymentSuccess(response.data.user));
    if (typeof action.payload.successAction === "function") {
      action.payload.successAction(response.data);
    }
  } catch (error) {
    yield put(postPaymentFailure(error));
    if (typeof action.payload.errorAction === "function") {
      action.payload.errorAction(error);
    }
  }
}

function* getPaymentSaga(action) {
  const token = yield select((state) => state?.common?.token);
  try {
    const response = yield call(getPaymentApi, action.payload.orderId, token);
    console.log("userdata", response.data);
    yield put(getPaymentSuccess(response.data.user));
    if (typeof action.payload.successAction === "function") {
      action.payload.successAction(response.data);
    }
  } catch (error) {
    yield put(getPaymentFailure(error));
    if (typeof action.payload.errorAction === "function") {
      action.payload.errorAction(error);
    }
  }
}

function* getUserProfileSaga(action) {
  const token = yield select((state) => state?.common?.token);
  try {
    const response = yield call(getUserProfileApi, token);
    console.log("userdata", response.data);
    yield put(getUserProfileSuccess(response.data));
    if (typeof action.payload.successAction === "function") {
      action.payload.successAction(response.data);
    }
  } catch (error) {
    yield put(getUserProfileFailed(error));
    if (typeof action?.payload?.errorAction === "function") {
      action.payload.errorAction(error);
    }
  }
}

function* postOnBoardingSaga(action) {
  const token = yield select((state) => state?.common?.token);
  try {
    const response = yield call(postOnBoardingApi, action.payload, token);
    console.log("userdata", response.data);
    yield put(postUserOnboardingSuccess(response.data));
    if (typeof action.payload.successAction === "function") {
      action.payload.successAction(response?.data?.orderDetails);
    }
  } catch (error) {
    yield put(postUserOnboardingFailure(error));
    if (typeof action.payload.errorAction === "function") {
      action.payload.errorAction(error);
    }
  }
}

function* getUserPaymentSaga(action) {
  const token = yield select((state) => state?.common?.token);
  try {
    const response = yield call(getUserPaymentApi, token, action?.payload?.id);
    yield put(getUserPaymentSuccess(response.data));
    if (typeof action.payload.successAction === "function") {
      action.payload.successAction(response.data);
    }
  } catch (error) {
    yield put(getUserPaymentFailure(error));
    if (typeof action.payload.errorAction === "function") {
      action.payload.errorAction(error);
    }
  }
}

export default function* commonSaga() {
  yield takeLatest(SENT_OTP_REQ, postSendOtpSaga);
  yield takeLatest(VERIFY_OTP_REQ, postVerifyOtpSaga);
  yield takeLatest(FETCH_USER_DATA_REQ, getUserDataSaga);
  yield takeLatest(POST_USER_DETAILS_REQ, postUserDetailsSaga);
  yield takeLatest(POST_PAYMENT_REQ, postPaymentSaga);
  yield takeLatest(GET_PAYMENT_REQ, getPaymentSaga);
  yield takeLatest(CHECK_PROFILE_STATUS_REQ, getUserProfileSaga);
  yield takeLatest(POST_USER_ONBOARDING_REQ, postOnBoardingSaga);
  yield takeLatest(GET_USER_PAYMENT_REQ, getUserPaymentSaga);
}
