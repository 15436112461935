import React, { useEffect, useRef, useState } from "react";
import SampleVideo from "./../../Images/bdhaoVid.mp4";
import VideThumb from "./../../Images/videThumb.webp";

const VideoCard = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayVideo = () => {
    if (videoRef.current) {
      if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      } else if (videoRef.current.mozRequestFullScreen) {
        videoRef.current.mozRequestFullScreen();
      } else if (videoRef.current.webkitRequestFullscreen) {
        videoRef.current.webkitRequestFullscreen();
      } else if (videoRef.current.msRequestFullscreen) {
        videoRef.current.msRequestFullscreen();
      }
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const onFullScreenChange = () => {
      if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        videoRef.current.pause(); // Pause video when exiting full-screen
        setIsPlaying(false); // Reset state to show the poster
      }
    };

    document.addEventListener("fullscreenchange", onFullScreenChange);
    document.addEventListener("webkitfullscreenchange", onFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", onFullScreenChange);
      document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
    };
  }, []);

  return (
    <div className="flex items-center justify-center p-4 rounded-2xl bg-dark-bl-clr">
      <div className="flex items-start gap-3">
        <div className="h-fit">
          {/* {!isPlaying && ( */}
          <div className="flex items-center justify-center cursor-pointer" onClick={handlePlayVideo}>
            {/* Optional Play Icon Here */}
            <img src={VideThumb} alt="" style={{ maxWidth: "240px", height: "120px" }} />
          </div>
          {/* )} */}
          <video
            ref={videoRef}
            className="rounded-lg"
            width="0px"
            height="0px"
            controls={isPlaying}
            // className="invisible"
            // poster={VideThumb} // Ensure the poster is applied when not playing
          >
            <source src={SampleVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="space-y-2 text-white h-fit">
          {!isPlaying ? (
            <button
              onClick={handlePlayVideo}
              className="px-4 py-2 font-semibold rounded-lg cursor-pointer text-def-clr text-12 gradient-btn"
            >
              Watch video
            </button>
          ) : (
            <button
              onClick={handlePlayVideo}
              className="px-4 py-2 font-semibold rounded-lg cursor-pointer text-def-clr text-12 gradient-btn"
            >
              Watch video
            </button>
          )}
          <h2 className="font-semibold text-12">Score Badhao Ch 1</h2>
          <p className="font-normal text-white opacity-60 text-10">
            In this video: You will see how to improve your credit history and easy action that you can take now.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
