import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendOtpReq, verifyOtpReq } from "./../redux/actions/common.js";
import { addCountryCode } from "./../utils/methods.js";
import CustomInput from "../Components/Input.jsx";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { userData } = useSelector((state) => state.common);
  // useEffect(
  //   () => {
  //     if (userData?.firstName && userData?.onboardingStatus === "COMPLETED") {
  //       navigate("/score");
  //     } else {
  //       navigate("/user")
  //     }
  //   },
  //   [userData?.id]
  // );

  useEffect(() => {
    // Web OTP API for auto-reading OTP from SMS (works only on supported browsers)
    if ("OTPCredential" in window) {
      window.addEventListener("DOMContentLoaded", (e) => {
        const ac = new AbortController();
        navigator.credentials
          .get({
            otp: { transport: ["sms"] },
            signal: ac.signal,
          })
          .then((otp) => {
            setOtp(otp.code);
          })
          .catch((err) => console.log("OTP auto-read failed:", err));
      });
    }
  }, []);

  // Helper function to validate mobile number
  const validateMobileNumber = (number) => {
    const regex = /^[6-9]\d{9}$/;
    if (!regex.test(number)) {
      return {
        code: -1,
        message: "Please enter a valid 10-digit Indian mobile number",
      };
    }
    return { code: 0, message: "Valid mobile number" };
  };

  const handleLogin = (mob) => {
    setError("");
    setOtpError("");
    const validationResult = validateMobileNumber(mob);
    if (validationResult.code !== 0) {
      setError(validationResult.message);
      return;
    }

    setShowOtp(true);
    dispatch(sendOtpReq({ mobileNumber: addCountryCode(mob) }));
  };

  // Handle OTP change
  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
    }
  };
  useLayoutEffect(() => {
    const body = document.body;
    body.style.background = "white";
  }, []);

  const handleOtpSubmit = (e) => {
    if (otp.length < 6) {
      setOtpError("Please enter a valid 6-digit OTP");
      return;
    }
    setLoading(true);
    dispatch(
      verifyOtpReq({
        mobileNumber: addCountryCode(mobileNumber),
        otp: otp,
        successAction: (res) => {
          if (
            res?.token?.user?.firstName &&
            res?.token?.user?.onboardingStatus === "COMPLETED"
          ) {
            setLoading(true);
            navigate("/score");
          } else {
            setLoading(true);
            navigate("/user");
          }
        },
        errorAction: () => {
          setLoading(true);
          setOtpError("Enter a valid OTP");
        },
      })
    );
  };
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  }, [loading]);

  return (
    <div className="flex relative flex-col justify-center items-center px-6 py-14 h-screen rounded-2xl md:bg-blueV1 po:items-start md:m-10p md:h-1/2">
      <div className="max-w-md text-30 font-700 h-max w-87p">
        Hi, Sign in with phone number
      </div>
      <div className="flex flex-col gap-10 justify-between pt-20 w-full max-w-md h-full rounded-2xl md:bg-transparent">
        <div className="flex flex-col gap-10">
          <div>
            {/* {error && (
              <div className="p-2 mb-4 text-red-700 bg-red-100 rounded">
                {error}
              </div>
            )} */}
            <CustomInput
              label="Phone Number"
              placeholder="Linked Phone Number"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
                if (e?.target?.value?.length > 9) {
                  setDisabled(false);
                  handleLogin(e?.target?.value);
                  setShowOtp(true);
                } else {
                  setShowOtp(false);
                }
              }}
              type="text"
              id="mobile"
              name="mobile"
              maxLength="10"
              isNumeric={true} // Set this to true for number-only input
              error={error}
              customClass={"bg-white text-black"}
              borderClass={"border-black"}
            />
          </div>

          {/* <div className="mb-4">
            <label
              htmlFor="mobile"
              className="block text-sm font-medium text-white"
            >
              Mobile Number
            </label>
            <input
              type="text"
              id="mobile"
              name="mobile"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
                setShowOtp(false);
              }}
              className="block px-3 py-2 mt-1 w-full rounded-md border border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              placeholder="Enter your mobile number"
              maxLength="10"
            />
          </div> */}
          {showOtp && (
            <div>
              {/* <input
                type="text"
                value={otp}
                onChange={handleOtpChange}
                maxLength="6"
                className="w-full text-lg text-center rounded border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                placeholder="Enter 6-digit OTP"
              /> */}
              <CustomInput
                label="Enter OTP"
                placeholder="6 digit OTP"
                value={otp}
                onChange={handleOtpChange}
                type="text"
                id="otp"
                name="otp"
                maxLength="6"
                isNumeric={true}
                error={otpError}
                customClass={"bg-white text-black"}
                borderClass={"border-black"}
              />
              {/* {otpError && <div className="mb-4 text-red-600">{otpError}</div>} */}
              {/* <button
                type="submit"
                className="px-4 py-2 w-full text-white rounded gradient-btn"
              >
                Verify OTP
              </button> */}
              {/* </form> */}
            </div>
          )}
        </div>
        <button
          type="submit"
          className={`px-4 cursor-pointer py-2 po:fixed  bottom-4 left-5 right-5 text-black rounded gradient-btn ${
            disabled || loading ? "opacity-60 pointer-events-none" : ""
          }`}
          onClick={handleOtpSubmit}
          disabled={disabled}
        >
          {loading ? (
            <svg
              className="mr-3 -ml-1 w-5 h-5 text-white animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              />
              <path
                className="opacity-75"
                fill="#000000"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              />
            </svg>
          ) : null}
          Verify with OTP
        </button>
      </div>
    </div>
  );
};

export default Login;
